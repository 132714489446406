<template>
  <div>
    <v-card v-if="canSendMessage">
      <v-card-title>
        Send Message
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-card-text>
          <v-alert
            v-if="success"
            type="success"
          >
            Your message was successfully sent.
          </v-alert>
          <v-alert
            v-for="error in errorMessages"
            :key="error"
            type="error"
          >
            {{ error }}
          </v-alert>
          <ProfileMultiSelect
            v-model="usernameList"
            :default-username-list="defaultUsernames"
            :disabled="submitting"
            label="To"
          />
          <v-text-field
            v-model="subject"
            label="Subject"
            type="text"
            :disabled="submitting"
            :rules="messageSubjectRules"
          />
          <v-textarea
            v-model="content"
            label="Message"
            auto-grow
            :disabled="submitting"
            :rules="messageContentRules"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary"
            type="submit"
            :disabled="submitting"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-alert
      v-else
      type="error"
    >
      You must be online to send a message.
    </v-alert>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { messageContentRules, messageSubjectRules } from '@/utils/rules'
import ProfileMultiSelect from '@/components/ProfileMultiSelect.vue'

export default {
  components: {
    ProfileMultiSelect,
  },
  data: function() {
    return {
      usernameList: null,
      success: null,
      errorMessages: null,
      content: '',
      subject: '',
      submitting: false,
      defaultUsernames: [],
      messageContentRules: messageContentRules,
      messageSubjectRules: messageSubjectRules,
    }
  },
  computed: {
    canSendMessage() {
      return this.$online
    }
  },
  created: function () {
    const queryRecipient = this.$route.query.to
    if (queryRecipient) {
      this.defaultUsernames = [queryRecipient]
    }
  },
  methods: {
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      const data = new FormData()
      data.append('content', this.content)
      data.append('subject', this.subject)
      for (let i = 0; i < this.usernameList.length; i++) {
        data.append('to_users', this.usernameList[i]);
      }
      return allianceAPI.post('/alliance/messages/create/', data)
        .then(() => {
          this.success = true
          this.errorMessages = null
          this.$refs.form.reset()
          this.$store.dispatch('messages/getAll')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
